import React from "react"

import DocumentHead from "../components/DocumentHead"
import Layout from "../components/Layout"

export const Head = () => <DocumentHead pageTitle="Matthew Dreyer | Home" />

const Item = ({ title, text, url }) => (
  <>
    <dt>{title}</dt>
    <dd>
      <a target="_blank" rel="noopener noreferrer" href={url} title={title}>
        {text}
      </a>
    </dd>
  </>
)

const IndexPage = () => (
  <Layout>
    <article>
      <p>You can reach me below:</p>
      <dl>
        <Item
          title="LinkedIn"
          text="linkedin.com/in/matthewdreyer"
          url="https://linkedin.com/in/matthewdreyer"
        />
        <Item
          title="Twitter"
          text="twitter.com/Dreyer"
          url="https://twitter.com/Dreyer"
        />
      </dl>
    </article>
  </Layout>
)

export default IndexPage
